
import Vue from "vue"
export default Vue.extend({
	name: "OidcCallback",
	async mounted() {
		this.$vStore.dispatch("oidcStore/oidcSignInCallback")
			.then((redirectPath) => {
				this.$router.push(redirectPath)
			})
			.catch((err) => {
				this.$router.push("/oidc-callback-error")
			})
	},
})
